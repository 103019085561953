<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "@/components/elements/DataViewer.vue";
import { GetAgentById, UpdateAgent } from "../../../../services/agents";
import { required, numeric } from "vuelidate/lib/validators";
import swal from "sweetalert2";

/**
 * Typography component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "Profile", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader },
  data() {
    return {
      title: this.$t("profile"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("profile"),
          active: true,
        },
      ],
      data: [
        { key: this.$t("name"), value: "lorem" },
        { key: this.$t("phone"), value: "lorem" },
        {
          key: this.$t("address"),
          value: "lorem lorem",
        },
        { key: this.$t("dashboard.number_of_questions.text"), value: 20 },
        { key: this.$t("dashboard.number_of_consultation.text"), value: 10 },
        { key: this.$t("dashboard.number_of_contracts.text"), value: 5 },
      ],

      editData: [],
      form: { name: "", Phone: "", address: "", password: "" },
    };
  },
  methods: {
    editClicked(item) {
      console.log(item);
      const index = this.tableData.findIndex((el) => el.id === item.item.id);
      console.log(index);
      this.editData.Type = this.tableData[index].display_name;

      this.$bvModal.show("modal-edit");
    },
    add() {
      this.$bvModal.show("modal-add");
    },
    loadAgent() {
      GetAgentById(this.$route.params.id).then((data) => {
        this.data = data;
        console.log(this.data);
      });
    },
    editShow() {
      this.editData = this.data;
      this.$bvModal.show("modal-backdrop");
    },
    edit() {
      // this.$bvModal.show("modal-add");
      this.$v.editData.$touch();

      if (this.$v.editData.$invalid) {
        return;
      } else {
        console.log(this.editData);
        UpdateAgent(this.$route.params.id, this.editData).then((data) => {
          console.log(data);
          this.loadAgent();
          this.successmsg("Agent has been Updated successfully");
          this.$bvModal.hide("modal-edit");
          this.$bvModal.hide('modal-backdrop')
        });
      }
    },
    successmsg(msg) {
      swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
  computed: {
    Agent() {
      return [
        { key: this.$t("name"), value: this.data.name },
        { key: this.$t("phone"), value: this.data.phone },
        { key: this.$t("email"), value: this.data.email },
        {
          key: this.$t("dashboard.number_of_contracts.text"),
          value: this.data.no_of_contracts,
        },
        {
          key: this.$t("dashboard.number_of_questions.text"),
          value: this.data.no_of_questions,
        },
        { key: this.$t("total_get"), value: this.data.total_get },
        {
          key: this.$t("role"),
          value: this.data.role === 0 ? "Admin" : "Agent",
        },
        {
          key: this.$t("privileges"),
          value: this.data?.privileges?.map((item) => {
            // Modify the item based on a condition
            if (item === "dashboard") {
              // Modify the item's value here
              item = this.$t("menuitems.dashboard.text");
            } else if (item === "agent") {
              item = this.$t("menuitems.agents.text");
            } else if (item === "client") {
              item = this.$t("menuitems.clients.text");
            } else if (item === "consultant") {
              item = this.$t("menuitems.consultant.text");
            } else if (item === "contract") {
              item = this.$t("menuitems.contracts.text");
            } else if (item === "question") {
              item = this.$t("menuitems.questions.text");
            } else if (item === "payment") {
              item = this.$t("menuitems.payments.text");
            } else if (item === "contract_type") {
              item = this.$t("menuitems.contract_types.text");
            }
            return item;
          }),
          tags: true,
        },
      ];
    },
  },
  validations: {
    editData: {
      name: {
        required,
      },
      phone: {
        required,
        numeric,
        phone: (value) => {
          // Validate that the phone number has exactly 11 numeric digits
          return /^[0-9]{11}$/.test(value);
        },
      },
    },
  },
  created() {
    this.loadAgent();
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <DataViewer :title="$t('profile')" :data="Agent" />
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <b-button @click="editShow()" style="width: 200px" variant="warning">
          {{ $t("edit_profile") }}
        </b-button>
        <b-modal
          no-close-on-backdrop
          id="modal-backdrop"
          :title="$t('edit_profile')"
          centered
          title-class="font-18"
        >
          <template #modal-footer>
            <div></div>
          </template>
          <form @submit.prevent="edit()" ref="modal-edit">
            <input
              type="text"
              :placeholder="$t('name')"
              v-model="editData.name"
              class="form-control mb-4"
              required
            />
            <input
              type="number"
              :placeholder="$t('phone')"
              v-model="editData.phone"
              class="form-control mb-4"
              required
              :class="{
                'is-invalid': $v.editData.phone.$error,
              }"
            />
            <span class="invalid-feedback" v-if="!$v.editData.phone.phone"
              >please Enter Valid 11 digit Phone number</span
            >

            <input
              type="password"
              :placeholder="$t('password')"
              v-model="editData.password"
              class="form-control mb-4"
            />
            <b-row
              ><b-col cols="8"></b-col>
              <b-col cols="2"
                ><b-button @click="$bvModal.hide('modal-backdrop')">{{
                  $t("cancel")
                }}</b-button>
              </b-col>
              <b-col cols="2">
                <b-button variant="primary" type="submit">{{
                  $t("ok")
                }}</b-button></b-col
              ></b-row
            >
          </form>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
